export interface DaysUnits {
  id?: number;
  dayTemplateID?: number;
  unitRoles?: Array<DaysUnitsRole>;
  name?: string;
  color?: string;
  editTime?: Date;
  timeBankFactor?: number;
  sortOrder?: number;
  readonly lastChanged?: number;
}

export interface DaysUnitsRole {
  id?: number;
  dayUnitTemplateID?: number;
  startTime?: string;
  endTime?: string;
  applyOnDay?: number;
  applyOnDate?: string;
  sortOrder?: number;
  dayUnitTemplateName?: string;
  editTime?: Date;
  disaplyText?: string;
  past?: boolean;
  readonly lastChanged?: number;
}

export interface DaysTemplates {
  id?: number;
  name?: string;
  timeRegMode?: number;
  units?: Array<DaysUnits>;
  editTime?: Date;
  time?: boolean;
  clock?: boolean;
  overtimeAndFlextime?: boolean;
  mustSpecHours?: boolean;
  allowMultiReg?: boolean;
  hoursInSemester?: boolean;
  mustSpecObj?: boolean;
  readonly lastChanged?: number;
}

export enum TimeRegMode {
  None = 0,
  Time = 1,
  Clock = 2,
  OvertimeAndFlextime = 4,
  MustSpecHours = 8,
  AllowMultiReg = 16,
  HoursInSemester = 32,
  MustSpecObj = 64,
}

export enum WeekDays {
  None = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 4,
  Thursday = 8,
  Friday = 16,
  Saturday = 32,
  Sunday = 64,
}

export enum RuleType {
  Day,
  Date,
}

export enum RuleOperation {
  Close,
  Delete,
  Add,
  Update,
}
