import { inject, Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { KEY_CODE } from '@app/constants/common.constant';
import { GA_EVENTS } from '@app/constants/google-analytics-events.constant';
import {
  ERROR_MESSAGES,
  MESSAGES_TRANSLATION_KEYS,
} from '@app/constants/message.constant';
import { BiogasAnalysis } from '@app/views/biogas/biogas.types';
import {
  DaysUnitsRole,
  WeekDays,
} from '@app/views/overtime-templates/overtime-template.types';
import { DEFAULT_LANGUAGE, LANGUAGE_CONSTANTS } from '@constants/app.constant';
import { LOCAL_STORAGE_CONSTANT } from '@constants/localstorage.constant';
import { TranslateService } from '@dv/translate';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { compareAsc, compareDesc, Locale, parseISO } from 'date-fns';
import { enUS, fi, nb, sv } from 'date-fns/locale';
import { AnalyticsService } from '../google-analytics/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  private translateService = inject(TranslateService);
  private localStorageService = inject(LocalStorageService);
  private analyticsService = inject(AnalyticsService);

  private isThrottled = false;

  maxLengthErrorMsg = this.translateService.t(
    MESSAGES_TRANSLATION_KEYS.maxLengthErrorMsg,
    ERROR_MESSAGES.maxLengthErrorMsg,
  );

  mandatoryFieldErrorMsg = this.translateService.t(
    MESSAGES_TRANSLATION_KEYS.mandatoryFieldErrorMsg,
    ERROR_MESSAGES.mandatoryFieldErrorMsg,
  );

  emailFieldErrorMsg = this.translateService.t(
    MESSAGES_TRANSLATION_KEYS.emailFieldErrorMsg,
    ERROR_MESSAGES.emailFieldErrorMsg,
  );

  setLanguage(): void {
    const language = this.getLanguage();
    this.translateService.setLang(language);
    this.localStorageService.set(
      LOCAL_STORAGE_CONSTANT.CURRENT_LANGUAGE_STATE_KEY,
      language,
    );
  }

  getLanguage(): string {
    const localStorageLanguage = this.localStorageService.get(
      LOCAL_STORAGE_CONSTANT.CURRENT_LANGUAGE_STATE_KEY,
    ) as string;
    const language = localStorageLanguage || DEFAULT_LANGUAGE;
    return language;
  }

  getLanguageLocaleCode(): Locale {
    switch (this.getLanguage()) {
      case LANGUAGE_CONSTANTS.sv:
        return sv;
      case LANGUAGE_CONSTANTS.fi:
        return fi;
      case LANGUAGE_CONSTANTS.nb:
      case LANGUAGE_CONSTANTS.no:
        return nb; // Using Norwegian Bokmål instead of Norwegian-Norsk for localise
      default:
        return enUS;
    }
  }

  changeLanguage(locale: string): void {
    const GA_EVENT = GA_EVENTS.LANGUAGE_CHANGE;
    GA_EVENT.label = locale;
    this.analyticsService.trackEvent(GA_EVENT);
    this.localStorageService.set(
      LOCAL_STORAGE_CONSTANT.CURRENT_LANGUAGE_STATE_KEY,
      locale,
    );
    this.translateService.setLang(locale);
  }

  isInvalidControl(control: AbstractControl): boolean {
    return control.invalid && (control.dirty || control.touched);
  }

  noWhitespaceValidator(control: AbstractControl) {
    return (control.value || '').trim().length ? null : { whitespace: true };
  }

  invalidControl(control: AbstractControl, max?: number) {
    if (this.isInvalidControl(control)) {
      if (control.errors.email) {
        return this.emailFieldErrorMsg;
      }
      return control.errors.required
        ? this.mandatoryFieldErrorMsg
        : `${this.maxLengthErrorMsg} ${max}`;
    } else {
      return false;
    }
  }

  groupByFieldToMap<T>(arr: T[], fieldName: string): Map<string, T[]> {
    const groupedMap = new Map();

    arr.forEach((obj) => {
      const fieldValue = obj[fieldName];
      if (!groupedMap.has(fieldValue)) {
        groupedMap.set(fieldValue, []);
      }
      groupedMap.get(fieldValue).push(obj);
    });

    return groupedMap;
  }

  compareDates(a: string | Date, b: string | Date, orderBy: 'asc' | 'desc') {
    const dateA = typeof a === 'string' ? parseISO(a) : a;
    const dateB = typeof b === 'string' ? parseISO(b) : b;
    return orderBy === 'asc'
      ? compareAsc(dateA, dateB)
      : compareDesc(dateA, dateB);
  }

  getFriendlyDays(applyOnDay: WeekDays): string {
    if (
      applyOnDay ==
      (WeekDays.Monday |
        WeekDays.Tuesday |
        WeekDays.Wednesday |
        WeekDays.Thursday |
        WeekDays.Friday)
    )
      return 'Weekdays';
    else if (
      applyOnDay ==
      (WeekDays.Monday |
        WeekDays.Tuesday |
        WeekDays.Wednesday |
        WeekDays.Thursday)
    )
      return 'Monday to thursday';
    else if (
      applyOnDay ==
      (WeekDays.Saturday | WeekDays.Sunday | WeekDays.Friday)
    )
      return 'Weekends';
    else if (
      applyOnDay ==
      (WeekDays.Monday |
        WeekDays.Tuesday |
        WeekDays.Wednesday |
        WeekDays.Thursday |
        WeekDays.Friday |
        WeekDays.Saturday |
        WeekDays.Sunday)
    )
      return 'All week';

    let days = '';
    if ((applyOnDay & WeekDays.Monday) === WeekDays.Monday) days += 'Monday, ';
    if ((applyOnDay & WeekDays.Tuesday) === WeekDays.Tuesday)
      days += 'Tuesday, ';
    if ((applyOnDay & WeekDays.Wednesday) === WeekDays.Wednesday)
      days += 'Wednesday, ';
    if ((applyOnDay & WeekDays.Thursday) === WeekDays.Thursday)
      days += 'Thursday, ';
    if ((applyOnDay & WeekDays.Friday) === WeekDays.Friday) days += 'Friday, ';
    if ((applyOnDay & WeekDays.Saturday) === WeekDays.Saturday)
      days += 'Saturday, ';
    if ((applyOnDay & WeekDays.Sunday) === WeekDays.Sunday) days += 'Sunday';
    days = days.replace(/,\s*$/, '');
    return days;
  }

  toTitleCase(text: string): string {
    return text.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase(),
    );
  }

  addThrottle(callback: () => void) {
    if (!this.isThrottled) {
      callback();
      this.isThrottled = true;
      setTimeout(() => {
        this.isThrottled = false;
      });
    }
  }

  timeToHours(time: string): number {
    const timeValue = time || '00:00';
    const [hours, minutes] = timeValue.split(':').map(Number);
    const isNegative = hours < 0;
    const absoluteHours = Math.abs(hours);
    const minutesInHour = 60;
    return (isNegative ? -1 : 1) * (absoluteHours + minutes / minutesInHour);
  }

  hoursToTime(hours: number) {
    const isNegative = hours < 0;
    const absoluteHours = Math.abs(hours);
    const minutesInHour = 60;
    const totalMinutes = Math.round(absoluteHours * minutesInHour);
    const formattedHours = Math.floor(totalMinutes / minutesInHour);
    const formattedMinutes = totalMinutes % minutesInHour;
    const paddedHours = String(formattedHours).padStart(2, '0');
    const paddedMinutes = String(formattedMinutes).padStart(2, '0');
    return `${isNegative ? '-' : ''}${paddedHours}:${paddedMinutes}`;
  }

  preventSpaceKeyPressed(event) {
    if (event.keyCode === KEY_CODE.SPACE) {
      event.preventDefault();
    }
  }

  roleDisplayName(role: DaysUnitsRole) {
    const unit = role.dayUnitTemplateName;
    const day =
      role.applyOnDay > 0 ? ' ' + this.getFriendlyDays(role.applyOnDay) : '';
    const date =
      new Date(role.applyOnDate) > new Date(1970, 1, 1)
        ? ' the: ' + role.applyOnDate
        : '';
    const startTime = role.startTime.slice(0, 5);
    const endTime = role.endTime.slice(0, 5);
    return this.translateService.t(
      '_time_rule_text',
      '_{0}, {1} {2} between: {3} - {4} ',
      unit,
      day,
      date,
      startTime,
      endTime,
    );
  }

  convertToObject(input) {
    const output = {};

    for (const key in input) {
      if (input?.[key]?._text !== undefined) {
        let value = input[key]._text;
        // Convert numeric strings to numbers
        if (!isNaN(value) && value.trim() !== '') {
          value = Number(value);
        }
        // Convert "true" and "false" strings to boolean values
        value = value === 'true' || (value !== 'false' && value);

        // Convert key to camelCase with the first letter in lowercase
        const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
        output[camelCaseKey] = value;
      }
    }

    return output;
  }

  getAnalysParam(weight: number, value: number) {
    return Math.round((value * weight) / 1000);
  }

  analysParamCalculation(
    analys: BiogasAnalysis,
    weight: number,
  ): BiogasAnalysis {
    const calculatedData = { ...analys };
    calculatedData.k = this.getAnalysParam(analys.k, weight);
    calculatedData.nh4 = this.getAnalysParam(analys.nh4, weight);
    calculatedData.p = this.getAnalysParam(analys.p, weight);
    calculatedData.ntot = this.getAnalysParam(analys.ntot, weight);
    calculatedData.norg = this.getAnalysParam(analys.norg, weight);
    calculatedData.ts = Math.round(analys.ts * weight) / 100;
    return calculatedData;
  }

  removeEmptyValues<T extends object>(obj: T): Partial<T> {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([_, v]) => v !== null && v !== '' && v !== undefined,
      ),
    ) as Partial<T>;
  }
}
